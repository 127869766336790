import en from 'vuetify/lib/locale/en'

export default {
  back: 'back',
  client: 'client',
  choiceOneVariant: 'choice one variant',
  comment: 'Comment',
  copySendManager: 'a copy will be emailed to the manager',
  enterClientCode: 'enter client code',
  enterYourName: 'enter your name',
  enterYourPhone: 'enter your phone',
  enterYourVariant: 'enter your variant',
  equipment: 'equipment',
  errorLoadingFromServer: 'error loading from server',
  fault: 'Fault',
  faultCodeIfAny: 'fault code, if any',
  faultGroup: 'Fault group',
  newOrder: 'New repair order',
  logInPlease: 'authorize please',
  location: 'location',
  next: 'next',
  noAccess: 'No access! Please check the correctness of the entered data',
  other: 'other',
  orderCreated: 'Order created',
  orderNumber: 'Order number',
  retry: 'retry',
  serviceStopped: 'Service is suspended, check with your manager for details.',
  success: 'success',
  wantAddSomething: 'want to add something?',
  validation: {
    phone: 'must be a valid phone: +XXXXXXXXXX',
    required: 'cannot be empty',
  },
  $vuetify: en
}
