import Vue from 'vue'
import Vuelidate from 'vuelidate'

import App from './App.vue'
import i18n from './i18n/i18n'
import ValidationMessages from './plugins/validationMessages'
import vuetify from './plugins/vuetify'
import router from './router'
import AxiosService from './services/axios.service'
import store from './store'

AxiosService.init(router, store)
Vue.config.productionTip = false

Vue.use(Vuelidate)
Vue.use(ValidationMessages)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
