import Vue from 'vue'
import VueRouter from 'vue-router'

import i18n from '@/i18n/i18n'
import store from '@/store'
import { SET_DOCUMENT_TITLE } from '@/store/mutations.type'

import nextFactory from './middleware'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:equipmentId',
    name: 'OrderCreate',
    component: () =>
      import(
        /* webpackChunkName: "orderCreate" */ '@/pages/orders/OrderCreate'
      ),
    props: true,
    meta: {
      title: 'newOrder'
    }
  },

  {
    path: '*',
    redirect: { name: 'Error' }
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/pages/ErrorPage.vue'),
    props: (route) => ({
      errorCode: route.params.errorCode || 404
    })
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      store,
      to
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

router.afterEach(async (to, from) => {
  Vue.nextTick(() => {
    const title = to.meta.title
      ? i18n.t(`${to.meta.title}`)
      : i18n.t('defaultPageTitle')
    store.commit(
      SET_DOCUMENT_TITLE,
      title.charAt(0).toUpperCase() + title.slice(1)
    )
  })
})

export default router
