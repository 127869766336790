import axios from 'axios'
import Vue from 'vue'
import VueAxios from 'vue-axios'

import axiosRetry from './axios-retry'

let isInitialized = false

const AxiosService = {
  init(router) {
    if (isInitialized) {
      throw new Error('Axios Service should be initialized only once!')
    }

    isInitialized = true

    Vue.use(VueAxios, axios)

    axiosRetry(Vue.axios, {
      retries: 5,
      retryDelay: axiosRetry.exponentialDelay
    })

    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
    Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

    Vue.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          if (error.response.status === 404) {
            if (router.currentRoute.name !== 'Error') {
              router.push({ name: 'Error' })
            }
          }
        }
        return Promise.reject(error)
      }
    )
  },

  setLanguageHeader(lang) {
    Vue.axios.defaults.headers.common['Accept-Language'] = lang
  }
}

export default AxiosService
