<template>
  <div id="app">
    <v-app>
      <v-app-bar
        app
        elevation="0"
      >
        <v-toolbar-title class="px-2 d-flex" style="width: 100%">
          <div class="d-flex">
            <v-img src="/logo.png" :aspect-ratio="5/1" :width="'158px'"/>
          </div>
          <v-spacer />
          <div class="d-flex">
            <locale-select />
          </div>
        </v-toolbar-title>
      </v-app-bar>
      <v-main>
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import LocaleSelect from './i18n/components/LocaleSelect.vue'

export default {
  components: {
    LocaleSelect
  },
  data() {
    return {}
  },
  computed: {},
  async beforeMount() {}
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
</style>
