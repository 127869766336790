import en from 'vuetify/lib/locale/en'
export default {
  back: 'უკან',
  client: 'კონტრაგენტი',
  choiceOneVariant: 'აირჩიეთ ერთი ვარიანტი',
  comment: 'კომენტარი',
  copySendManager: 'კოპია იქნება გაგზავნილი მენეჯერს ელ-ფოსტით',
  enterClientCode: 'შეიყვანეთ კლიენტის კოდი',
  enterYourName: 'შეიყვანეთ თქვენიимის სახელი',
  enterYourPhone: 'შეიყვანეთ თქვენი ტელეფონი',
  enterYourVariant: 'შეიყვანეთ თქვენი ვარიანტი',
  equipment: 'ტექნიკა',
  errorLoadingFromServer: 'შეცდომა სერვერიდან ჩატვირთვისას',
  fault: 'მოწყობა',
  faultCodeIfAny: 'თუ არის, მოწყობის კოდი',
  faultGroup: 'მოწყობების ჯგუფი',
  newOrder: 'ახალი რემონტის ორდერი',
  location: 'ლოკაცია',
  logInPlease: 'გთხოვთ, ავტორიზაცია ჩართეთ',
  next: 'შემდეგი',
  noAccess: 'წვდომა არ არის! გთხოვთ, შეამოწმეთ სწორად შეყვანილი ინფორმაცია',
  other: 'სხვა',
  orderCreated: 'ორდერი შექმნილია',
  orderNumber: 'ორდერ-ნომერი',
  retry: 'გამოიცანი ხელახლა',
  success: 'მიღებულია',
  serviceStopped:
    'ორდერების მომსახურება შეჩერებულია, დეტალები მართვისას მიუთითეთ თქვენს მენეჯერს.',
  wantAddSomething: 'გსურთ რამე დამატოთ?',
  validation: {
    phone: 'აუცილებელია ჭერის ნომრის შეყვანა: +XXXXXXXXXXXX',
    required: 'არ შეიძლება ცარიელი იყოს'
  },
  $vuetify: en
}
