import Vue from 'vue'
import Vuex from 'vuex'

import {
  SET_DOCUMENT_TITLE,
} from './mutations.type'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    documentTitle: '',
  },
  getters: {
    documentTitle: (state) => state.documentTitle,
  },
  mutations: {
    [SET_DOCUMENT_TITLE](state, title) {
      state.documentTitle = title
      document.title = title
    }
  },
  actions: {},
  modules: {},
})
